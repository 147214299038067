import AWS from "aws-sdk";
import compressImage from "./convertImg";
import { toast } from "react-hot-toast";

export const checkForImg = (ext: any) => {
  if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "webp") {
    return true;
  } else {
    return false;
  }
};

const UploadImage = ({ file, name }: { file: any; name: any }) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY_SECRET,
      region: process.env.REACT_APP_APP_REGION,
    });

    const s3 = new AWS.S3();

    if (checkForImg(name.split(".")[name.split(".").length - 1])) {
      compressImage(file, 0.1)
        .then((val) => {
          const params: any = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: name,
            Body: val,
            ACL: "public-read",
            Expires: 3600,
          };

          s3.putObject(params)
            .promise()
            .then(() => {
              // toast.success("image uploaded.")
              resolve(true);
            })
            .catch(() => {
              toast.error("something went wrong in images part.");
            });
        })
        .catch(() => {
          toast.error("something went wrong while compressing image.");
        });
    } else {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: name,
        Body: file,
        ACL: "public-read",
        Expires: 3600,
      };

      //   @ts-ignore
      s3.putObject(params)
        .promise()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          toast.error("something went wrong in images part.");
        });
    }
  });
};

export default UploadImage;
