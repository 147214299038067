import { useState } from "react";
import callApi from "../../Apis/callApi";
import styles from "../../Styles/Login.module.css";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (email && password) {
      axios({
        url: "https://backend.theholidayfeed.com/api/admins/adminlogin",
        method: "post",
        data: {
          email: email,
          password: password,
        },
      })
        .then((res) => {
          console.log(res);
          localStorage.setItem("ptblg_token", res.data.token);
          window.location.reload();
        })
        .catch((err: any) => {
          alert("Invalid Email or Password");
        });
    }
  };

  return (
    <div className={styles.container}>
      {/* header */}
      {/* <div className={styles.login_nav_container}>
				<div className={styles.logo}></div>
			</div> */}

      {/* other */}
      <div className={styles.modal_container}>
        <div className={styles.modal}>
          <h1>Admin Login</h1>
          <div className={styles.fields_container}>
            <div className={styles.label_and_field}>
              <span>Email</span>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className={styles.label_and_field}>
              <span>Password</span>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
