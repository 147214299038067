import { faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "../../../Styles/applayout.module.css";
import { sidebar_data } from "../../../Utils/Data";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Ad } from "../../../Assets/ad.svg";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.sidebar_container}>
      <div
        className={styles.sidebar_main}
        style={{ width: open ? "200px" : "" }}
      >
        <div
          className={`${styles.menu_icon_container} ${
            open ? styles.open_menu_icon : ""
          }`}
          onClick={() => {
            setOpen((old) => !old);
          }}
        >
          {/* line 1 */}
          <div className={styles.menu_line_one}></div>
          {/* line 2 */}
          <div className={styles.menu_line_two}></div>
          {/* line 3 */}
          <div className={styles.menu_line_three}></div>
        </div>
        <ul className={styles.menu_items_container}>
          {sidebar_data.map((val, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  navigate(val.link);
                }}
              >
                {typeof val.icon === "string" ? (
                  <Ad />
                ) : (
                  <FontAwesomeIcon icon={val.icon} />
                )}
                <span
                  className={`${styles.sidebar_text} ${
                    open && styles.sidebar_text_show
                  }`}
                >
                  {val.title}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
