import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import { useNavigate } from "react-router-dom";
import withRouter from "../../../Utils/withRouter";
import InputField from "../../../Components/Common/Custom/InputField";
import axios from "axios";
import { toast } from "react-hot-toast";
import UploadImage from "../../../Utils/UploadImage";
import { isValidURL } from "../../../Utils/utils";
import { getExtendedFileName } from "../../../Utils/ImageHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

class CreateNewAd extends Component<any, any> {
  state = {
    loading: false,
    imageUrl: "",
    imageObject: "",
    title: "",
    brand: "",
    clickUrl: "",
    category: "",
    isImageChanged: false,
    addedBlogs: [],
    removedBlogs: [],
  };

  componentDidMount(): void {
    if (this.props.router.params?.id) {
      this.getData();
      this.setState({ loading: true });
    }
  }

  getData = () => {
    axios({
      method: "get",
      url: `https://backend.theholidayfeed.com/api/advertisements/${this.props.router.params?.id}`,
    })
      .then((res) => {
        this.setState({
          imageUrl: res.data.advertisement.couponImage,
          title: res.data.advertisement.title,
          brand: res.data.advertisement.brand,
          clickUrl: res.data.advertisement.clickUrl,
          category: res.data.advertisement.category,
        });

        this.getBlogs();
      })
      .catch((errror) => {
        toast.error(errror.response.data.message);
        this.setState({ loading: false });
      });
  };

  getBlogs = () => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/pets/",
    })
      .then((res: any) => {
        console.log(res.data.pets);
        const cpns = res.data.pets;

        const data = cpns.filter((val: any) => {
          return val.ads.includes(this.props.router.params.id);
        });

        this.setState({ addedBlogs: [...data], loading: false });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  handleImage = (event: any) => {
    this.setState({
      imageUrl: URL.createObjectURL(event.target.files[0]),
      imageObject: event.target.files[0],
    });
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  validate = () => {
    return true;
    if (!this.state.imageUrl) {
      toast.error("Banner image is required.");
      return false;
    }

    if (!this.state.title) {
      toast.error("Title is required.");
      return false;
    }

    if (!this.state.brand) {
      toast.error("Brand is required.");
      return false;
    }

    if (!this.state.clickUrl) {
      toast.error("Click Url is required.");
      return false;
    }

    if (!isValidURL(this.state.clickUrl)) {
      toast.error("Click Url is invalid.");
      return false;
    }

    if (!this.state.category) {
      toast.error("Category is required.");
      return false;
    }

    return true;
  };

  createAd = () => {
    if (this.validate()) {
      this.setState({ loading: true });

      let filename = getExtendedFileName(this.state.imageObject);

      UploadImage({ file: this.state.imageObject, name: filename }).then(() => {
        axios({
          method: "post",
          url: "https://backend.theholidayfeed.com/api/advertisements/",
          data: {
            title: this.state.title,
            brand: this.state.brand,
            clickUrl: this.state.clickUrl,
            category: this.state.category,
            couponImage: filename,
            identifier: "thepetsfeed.com",
          },
          headers: { Authorization: localStorage.getItem("ptblg_token") },
        })
          .then((res) => {
            console.log("what is res here ", res);
            this.setState({ loading: false });
            toast.success("Ad created successfully");
            this.props.router.navigate("/ads");
          })
          .catch((err) => {
            this.setState({ loading: false });
            toast.error("Error in creating ad");
          });
      });
    }
  };

  updateAd = () => {
    if (this.validate()) {
      this.setState({ loading: true });
      if (this.state.isImageChanged) {
        // upload image

        let filename = getExtendedFileName(this.state.imageObject);

        UploadImage({ file: this.state.imageObject, name: filename }).then(
          () => {
            axios({
              method: "patch",
              url:
                "https://backend.theholidayfeed.com/api/advertisements/" +
                this.props.router.params?.id,
              data: {
                title: this.state.title,
                brand: this.state.brand,
                clickUrl: this.state.clickUrl,
                category: this.state.category,
                couponImage: filename,
                identifier: "thepetsfeed.com",
              },
              headers: { Authorization: localStorage.getItem("ptblg_token") },
            })
              .then(async (res) => {
                if (this.state.removedBlogs.length !== 0) {
                  for (let i = 0; i < this.state.removedBlogs.length; i++) {
                    // @ts-ignore
                    const ads = [...this.state.removedBlogs[i]?.ads];

                    const j = ads.indexOf(this.props.router.id);

                    ads.splice(j, 1);
                    try {
                      const response = await axios({
                        url:
                          "htts://backend.theholidayfeed.com/api/pets/" +
                          // @ts-ignore
                          this.state.removedBlogs[i]._id,
                        method: "patch",
                        data: {
                          ads: [...ads],
                        },
                      });

                      console.log(response);
                      this.setState({ loading: false });
                      toast.success("Ad updated successfully");
                      this.props.router.navigate("/ads");
                    } catch (err) {
                      toast.error("Error while removing ad from the blog");
                      this.setState({ loading: false });
                    }
                  }
                } else {
                  console.log("what is res here ", res);
                  this.setState({ loading: false });
                  toast.success("Ad updated successfully");
                  this.props.router.navigate("/ads");
                }
              })
              .catch((err) => {
                toast.error("Error in updating ad");
                this.setState({ loading: false });
              });
          },
        );
      } else {
        // no need to upload image
        axios({
          method: "patch",
          url:
            "https://backend.theholidayfeed.com/api/advertisements/" +
            this.props.router.params?.id,
          data: {
            title: this.state.title,
            brand: this.state.brand,
            clickUrl: this.state.clickUrl,
            category: this.state.category,
            imageUrl: this.state.imageUrl,
            identifier: "thepetsfeed.com",
          },
          headers: { Authorization: localStorage.getItem("ptblg_token") },
        })
          .then(async (res) => {
            if (this.state.removedBlogs.length !== 0) {
              for (let i = 0; i < this.state.removedBlogs.length; i++) {
                // @ts-ignore
                const ads = [...this.state.removedBlogs[i]?.ads];

                const j = ads.indexOf(this.props.router.id);

                ads.splice(j, 1);
                try {
                  const response = await axios({
                    url:
                      "htts://backend.theholidayfeed.com/api/pets/" +
                      // @ts-ignore
                      this.state.removedBlogs[i]._id,
                    method: "patch",
                    data: {
                      ads: [...ads],
                    },
                  });

                  console.log(response);
                  this.setState({ loading: false });
                  toast.success("Ad updated successfully");
                  this.props.router.navigate("/ads");
                } catch (err) {
                  toast.error("Error while removing ad from the blog");
                  this.setState({ loading: false });
                }
              }
            } else {
              console.log("what is res here ", res);
              this.setState({ loading: false });
              toast.success("Ad updated successfully");
              this.props.router.navigate("/ads");
            }
          })
          .catch((err) => {
            toast.error("Error in updating ad");
            this.setState({ loading: false });
          });
      }
    }
  };

  render() {
    return (
      <Content>
        <ContentHead
          title="New Ad"
          showBtn1={true}
          showBtn2={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text="Save"
          btn2Text="Cancel"
          btn1type="filled"
          btn2type="outlined"
          onClickHandler={(e: any) => {
            if (e.target.name === "btn2") {
              this.props.router.navigate("/ads");
            } else {
              if (this.props.router.params?.id) {
                this.updateAd();
              } else {
                this.createAd();
              }
            }
          }}
        />
        <ContentBody>
          <>
            {/* loader */}
            <div
              style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                background: "rgba(0,0,0,.3)",
                top: 0,
                left: 0,
                display: this.state.loading ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div className={"spin"}></div>
            </div>

            {/* image part */}
            <>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                Banner Image
                {this.state.imageUrl && (
                  <span
                    style={{
                      display: "inline-block",
                      background: "grey",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        imageUrl: "",
                        imageObject: "",
                        isImageChanged: true,
                      });
                    }}
                  >
                    Remove
                  </span>
                )}
              </p>

              {!this.state.imageUrl ? (
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    textAlign: "center",
                    background: "transparent",
                    border: "1px dashed grey",
                    borderRadius: "10px",
                    margin: "10px",
                    cursor: "pointer",
                    position: "relative",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  +
                  <input
                    type="file"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      cursor: "pointer",
                    }}
                    accept="image/*"
                    onChange={this.handleImage}
                  ></input>
                </div>
              ) : (
                <div
                  style={{
                    height: "250px",
                    aspectRatio: "16/9",
                    backgroundImage: this.props.router.params?.id
                      ? this.state.isImageChanged
                        ? `url('${this.state.imageUrl}')`
                        : `url('${
                            process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
                            this.state.imageUrl
                          }')`
                      : `url(${this.state.imageUrl})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    marginBottom: "40px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    position: "relative",
                    backgroundColor: "rgba(0,0,0,.05)",
                  }}
                ></div>
              )}
            </>

            {/* fields */}
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.title}
                  lable="Title"
                  isRequired={false}
                  placeholder="ad's title"
                  onChange={(event: any) =>
                    this.setState({
                      title: event.target.value,
                    })
                  }
                />
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.brand}
                  lable="Brand"
                  isRequired={false}
                  placeholder="Brand name"
                  onChange={(event: any) => {
                    this.setState({
                      brand: event.target.value,
                    });
                  }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.clickUrl}
                  lable="Click Url"
                  isRequired={false}
                  placeholder="click url"
                  onChange={(event: any) => {
                    this.setState({
                      clickUrl: event.target.value,
                    });
                  }}
                />

                <InputField
                  type="select"
                  isLabel={true}
                  value={this.state.category}
                  lable="Category"
                  isRequired={false}
                  options={[
                    { title: "Food", value: "food" },
                    { title: "Travel", value: "travel" },
                    { title: "Fashion", value: "fashion" },
                    { title: "Beauty", value: "beauty" },
                    { title: "Health & Fitness", value: "health&fitness" },
                    { title: "Hosting & VPNs", value: "hosting&vpns" },
                    { title: "Home Decor", value: "home decor" },
                    { title: "Gifting", value: "gifting" },
                    { title: "Entertainment", value: "entertainment" },
                    { title: "Education", value: "education" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  placeholder="category"
                  onChange={(event: any) => {
                    this.setState({
                      category: event.target.value,
                    });
                  }}
                />
              </div>

              {this.state.addedBlogs.length !== 0 && (
                <div style={{ paddingTop: "20px", fontWeight: 500 }}>
                  <h3>Blogs</h3>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    {this.state.addedBlogs.map((val: any, index: any) => {
                      console.log(val);

                      return (
                        <div
                          key={index}
                          style={{
                            width: "100%",
                            aspectRatio: "1/1",
                            borderRadius: "15px",
                            position: "relative",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            style={{
                              position: "absolute",
                              background: "lightgrey",
                              height: "20px",
                              width: "20px",
                              padding: "3px",
                              borderRadius: "100%",
                              right: 0,
                              top: 0,
                              transform: "translate(50%, -50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let temp = this.state.addedBlogs;
                              const removed = temp.splice(index, 1);
                              console.log(removed);
                              this.setState({
                                addedBlogs: [...temp],
                                removedBlogs: [
                                  ...this.state.removedBlogs,
                                  ...removed,
                                ],
                              });
                            }}
                          />
                          <div
                            style={{
                              height: "80%",
                              width: "100%",
                              background: "grey",
                              borderRadius: "15px 15px 0 0",
                            }}
                          >
                            <img
                              src={
                                "https://d2i3bvmeu1pjok.cloudfront.net/filters:format(webp)/" +
                                val.images[0]
                              }
                              alt={val.title}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                                borderRadius: "15px 15px 0 0",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              height: "20%",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              paddingInline: "10px",
                              background: "rgba(0,0,0,.05)",
                              borderRadius: "0 0 15px 15px",
                            }}
                          >
                            {val.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          </>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(CreateNewAd);
