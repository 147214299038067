import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTableColumns, faBox, faGear } from "@fortawesome/free-solid-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

type ISidebarData = { title: string; icon: IconProp; link: string };
type IBlogTableHead = { title: string };

export const sidebar_data: ISidebarData[] = [
	{ title: "Blogs", icon: faMailBulk, link: "/blogs" },
	{ title: "Ads", icon:"ad", link:"/ads"},
	{ title: "Custom", icon: faGear, link: "/custom"}
];

export const blog_table_head: IBlogTableHead[] = [
	{ title: "S.No" },
	{ title: "Blog Title" },
	{ title: "Actions" },
];

export const animal_dropdown = [
	{ name: "Dog", value: "dog" },
	{ name: "Cat", value: "cat" },
	{ name: "Fish", value: "fish" },
	{ name: "Horse", value: "horse" },
	{ name: "Rabit", value: "rabit" },
	{ name: "Hamster", value: "hamster" },
	{ name: "Cows", value: "cows" },
	{ name: "Sheeps", value: "sheeps" },
	{ name: "Goat", value: "goat" },
	{ name: "Birds", value: "birds" },
	{ name: "Guinea Pigs", value: "guinea_pig" },
	{ name: "Gerbils", value: "gerbils" },
	{ name: "Ferrets", value: "ferrets" },
	{ name: "Hedgehogs", value: "hedgehogs" },
	{ name: "Pet Rats", value: "pet_rats" },
	{ name: "Turtle", value: "turtle" },
	{ name: "Snake", value: "snake" },
	{ name: "Geckos", value: "geckos" },
	{ name: "Frogs", value: "frogs" },
	{ name: "Bearded Dragons", value: "bearded_dragons" },
	{ name: "Monkey", value: "monkey" },
	{ name: "Mule", value: "mule" },
	{ name: "Tortoise", value: "tortoise" },
	{ name: "Other", value:"other"}
];
