import styles from "../../../Styles/custom.module.css";

type props = {
	text: string | undefined;
	type: "outlined" | "filled" | undefined;
	style?: any;
	name: string | undefined;
	clickHandler: any;
};

const Button = (props: props) => {
	return (
		<button
			name={props.name}
			className={`${styles.button_container} ${
				props?.type ? styles[props.type] : ""
			}`}
			style={props?.style}
			onClick={(event: React.SyntheticEvent) => {
				props.clickHandler(event);
			}}
		>
			{props.text}
		</button>
	);
};

export default Button;
