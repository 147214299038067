import styles from "../../../Styles/applayout.module.css";
import { ReactComponent as Logo } from "../../../Assets/logo.svg";
import { ReactComponent as LogoText } from "../../../Assets/logo_text.svg";

const Navbar = () => {
  return (
    <div className={styles.nav_container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Logo
          style={{
            height: "20px",
            paddingRight: "10px",
          }}
        />
        <LogoText
          style={{
            height: "20px",
            paddingRight: "30px",
            borderRight: "1px solid black",
          }}
        />
        <span style={{ paddingLeft: "30px" }}>Admin</span>
      </div>
      <button
        onClick={() => {
          localStorage.removeItem("ptblg_token");
          localStorage.clear();
          window.location.reload();
        }}
      >
        Logout ⇥
      </button>
    </div>
  );
};

export default Navbar;
