import styles from "../../Styles/adsDrag.module.css";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  faClose,
  faCross,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Grip } from "./grip.svg";

const AdsDrag = ({ data, onSwap, onDelete, onEdit }: any) => {
  const swapPriority = (id1: any, id2: any, priority1: any, priority2: any) => {
    // make swap api call
    console.log("swap called");
    console.log(id1, id2, priority1, priority2);
    onSwap(id1, id2, priority1, priority2);
  };

  function handleDragEnd(event: any) {
    console.log("drag End called");
    const { active, over } = event;
    console.log(active, over);

    if (active.id !== over.id) {
      const draggedItem = data.find((item: any) => item.id === active.id);
      const targetItem = data.find((item: any) => item.id === over.id);
      console.log(draggedItem);
      console.log(targetItem);

      if (draggedItem && targetItem) {
        swapPriority(
          draggedItem.id,
          targetItem.id,
          draggedItem.priority,
          targetItem.priority
        );
      }
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className={styles.container}>
        <SortableContext items={data} strategy={verticalListSortingStrategy}>
          {data.map((val: any, index: any) => {
            return (
              <TableBodyRow
                val={val}
                index={index}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            );
          })}
        </SortableContext>
      </div>
    </DndContext>
  );
};

const TableBodyRow = ({ val, index, onDelete, onEdit }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: val.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
      }}
      className={styles.table_body_row}
    >
      <Grip
        style={{
          height: "calc(100% - 20px)",
          position: "absolute",
          padding: "10px 0",
          width: "20px",
          cursor: "grab",
          outline: "none",
          marginLeft: "10px",
        }}
        {...attributes}
        {...listeners}
      />
      <div
        className={styles.img_parts}
        style={{
          background: `url('${process.env.REACT_APP_IMAGE_BASE_FAST_LINK}${val.couponImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div>
        <p>{val.brand}</p>
        <h1>{val.title}</h1>
      </div>
      <div style={{ flexGrow: 1 }}></div>

      <FontAwesomeIcon
        icon={faClose}
        color={"red"}
        style={{
          fontSize: "1rem",
          zIndex: 10,
          cursor: "pointer",
          height: "1rem",
          position: "absolute",
          right: "20px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          onDelete(val, val.id);
        }}
      />
    </div>
  );
};

export default AdsDrag;
