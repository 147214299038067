import styles from "../../../Styles/inputField.module.css";
import { ReactComponent as Down } from "../../../Assets/down.svg";
import { useRef } from "react";

type Props = {
	isLabel: boolean;
	value: string;
	lable?: string;
	type: string;
	isRequired?: boolean;
	placeholder?: string;
	style?: any;
	name?: string;
	onChange: any;
	options?: any;
	accessText?: string | undefined;
	accessValue?: string | undefined;
};

const InputField = (props: Props) => {
	const selectRef: any = useRef();

	return (
		<>
			<div
				className={styles.field_contianer}
				style={props.style ? { ...props.style } : {}}
			>
				{props.isLabel && (
					<p>
						{props.lable} {props.isRequired && "*"}
					</p>
				)}
				{props.type === "select" ? (
					<div className={styles.select_container}>
						<select
							ref={selectRef}
							value={props.value}
							onChange={props.onChange}
							name={props?.name ? props.name : ""}
						>
							<option value="" disabled selected>
								Select your option
							</option>
							{props.options.map((val: any, index: any) => {
								return (
									<option key={index} value={val[props?.accessValue!]}>
										{val[props?.accessText!]}
									</option>
								);
							})}
						</select>
						<Down
							onClick={() => {
								if (selectRef.current) {
									selectRef.current?.click();
								}
							}}
						/>
					</div>
				) : props.type === "textarea" ? (
					<textarea className={styles.textarea}></textarea>
				) : (
					<input
						type={props.type}
						placeholder={props.placeholder}
						name={props?.name ? props.name : ""}
						value={props.value}
						onChange={props.onChange}
					></input>
				)}
			</div>
		</>
	);
};

export default InputField;
