import React from "react";
import Content from "../../Components/Common/Content/Content";
import ContentBody from "../../Components/Common/Content/ContentBody";
import ContentHead from "../../Components/Common/Content/ContentHead";
import Table from "../../Components/Common/Table/Table";
import styles from "../../Styles/dashboard.module.css";
import withRouter from "../../Utils/withRouter";
import NameWithDataCard from "../../Components/Dasboard/nameWithDataCard";
import callApi from "../../Apis/callApi";
import Button from "../../Components/Common/Custom/Button";

const cardData = [
	{ title: "Revenue" },
	{ title: "Orders" },
	{ title: "Queries" },
	{ title: "Other" },
];

const body_keys = ["productId", "quantity", "totalPrice", "createdAt"];
const body_keys_2 = ["fullName", "mobileNumber", "email"];

class Dashboard extends React.Component<any, any> {
	state = {
		recent_orders: [],
		data: [],
	};

	componentDidMount() {
		this.getRecentOrders();
		this.getQueries();
	}

	getRecentOrders = () => {};

	getQueries = () => {};

	render(): React.ReactNode {
		return (
			<Content>
				<ContentHead title="Dashboard" />

				{/* content */}
				<ContentBody>
					<></>
				</ContentBody>
			</Content>
		);
	}
}

export default withRouter(Dashboard);
