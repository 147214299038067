import { useEffect, useState } from "react";
import Content from "../../Components/Common/Content/Content";
import ContentBody from "../../Components/Common/Content/ContentBody";
import ContentHead from "../../Components/Common/Content/ContentHead";
import styles from "../../Styles/customPage.module.css";
import axios from "axios";
import InputField from "../../Components/Common/Custom/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import AdsDrag from "../../Components/AdsDrag";
import { toast } from "react-hot-toast";

const Custom = () => {
  const [loading, setLoading] = useState(true);
  const [ads, setAds] = useState([]);
  const [selectedAds, setSelectedAds]: any = useState([]);
  const [max, setMax] = useState(0);

  useEffect(() => {
    getAds();
  }, []);

  const getAds = () => {
    setLoading(true);
    axios({
      url: "https://backend.theholidayfeed.com/api/advertisements/?identifier=thepetsfeed.com",
      method: "get",
    })
      .then((res) => {
        setAds(res.data.advertisements.filter((val: any) => !val.onHome));
        setSelectedAds(
          res.data.advertisements
            .filter((val: any) => val.onHome)
            .sort((a: any, b: any) => a.priority - b.priority),
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleAdsChange = (event: any) => {
    if (selectedAds.length === 4 || selectedAds.length >= 4) {
      toast.error("You can only add 4 ads on homepage.");
    } else {
      // first find the ad
      let ad: any = ads.find((item: any) => item._id === event.target.value);

      axios({
        url:
          "https://backend.theholidayfeed.com/api/advertisements/" +
          event.target.value,
        method: "patch",
        data: {
          onHome: true,
          priority:
            selectedAds.length === 0
              ? 1
              : selectedAds[selectedAds.length - 1].priority + 1,
        },
        headers: {
          Authorization: localStorage.getItem("ptblg_token"),
        },
      })
        .then(() => {
          getAds();
        })
        .catch(() => {
          toast.error("Something went wrong");
        });

      console.log("ad found :: ", ad);
    }
  };

  const handleSelectedAdsRemove = (index: any) => {};

  const handleDel = (_: any, id: any) => {
    axios({
      url: "https://backend.theholidayfeed.com/api/advertisements/" + id,
      method: "patch",
      data: { onHome: false, priority: -1 },
      headers: {
        Authorization: localStorage.getItem("ptblg_token"),
      },
    })
      .then(() => {
        getAds();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  const handleSwap = async (
    id1: any,
    id2: any,
    priority1: any,
    priority2: any,
  ) => {
    let temp = [...selectedAds];

    let index1 = temp.findIndex((val: any) => val.id === id1);
    let index2 = temp.findIndex((val: any) => val.id === id2);

    // @ts-ignore
    temp[index1].priority = priority2;
    // @ts-ignore
    temp[index2].priority = priority1;

    temp.sort((a: any, b: any) => a.priority - b.priority);

    setSelectedAds(temp);

    setLoading(true);

    try {
      const r1 = await axios({
        url: "https://backend.theholidayfeed.com/api/advertisements/" + id1,
        method: "patch",
        data: {
          priority: priority2,
        },
        headers: {
          Authorization: localStorage.getItem("ptblg_token"),
        },
      });

      console.log(r1);

      const r2 = await axios({
        url: "https://backend.theholidayfeed.com/api/advertisements/" + id2,
        method: "patch",
        data: {
          priority: priority1,
        },
        headers: {
          Authorization: localStorage.getItem("ptblg_token"),
        },
      });

      console.log(r2);

      getAds();
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <Content>
      <ContentHead title="Custom" />
      <ContentBody
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <div className={styles.main}>
          {/* dropdown */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <InputField
              type="select"
              isLabel={true}
              value={""}
              lable="Advertisement (4 max)"
              isRequired={false}
              placeholder="Ads"
              options={ads}
              accessText="title"
              accessValue="_id"
              onChange={handleAdsChange}
            />
          </div>

          {/* sequence container */}
          <div
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <AdsDrag
              data={[...selectedAds]}
              onDelete={handleDel}
              onSwap={handleSwap}
            />
          </div>
        </div>
      </ContentBody>
    </Content>
  );
};

export default Custom;
