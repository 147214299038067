import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import Input from "../../../Components/Common/Custom/Input";
import TextEditor from "../../../Components/Common/TextEditor/TextEditor";
import InputField from "../../../Components/Common/Custom/InputField";
import withRouter from "../../../Utils/withRouter";
import callApi from "../../../Apis/callApi";
import UploadImage from "../../../Utils/UploadImage";
import { Country, City } from "country-state-city";
import axios from "axios";
import { toast } from "react-hot-toast";
import { animal_dropdown } from "../../../Utils/Data";
import CreateNewBlog from "../CreateNewBlog";

// class BlogDetails extends Component<any, any> {
// 	state = {
// 		imageUrl: "",
// 		imageObject: "",
// 		content: "",
// 		title: "",
// 		animal: "",
// 		shortDescription: "",
// 		blogType: "",
// 		blogCategory: "",
// 		loading: false,
// 		imageChanged: false,
// 	};

// 	componentDidMount(): void {
// 		this.setData();
// 	}

// 	setData = () => {
// 		this.setState({ loading: true });
// 		axios({
// 			method: "get",
// 			url: `https://backend.theholidayfeed.com/api/pets/${this.props.router.params.id}`,
// 		})
// 			.then((res) => {
// 				let data = res.data.pet;

// 				this.setState({
// 					title: data.title,
// 					animal: data.animal,
// 					shortDescription: data.shortDescription,
// 					content: data.description,
// 					imageUrl: data.images[0],
// 					blogType: data.blogType,
// 					blogCategory: data.blogCategory,
// 				});

// 				this.setState({ loading: false });
// 			})
// 			.catch((errror) => {
// 				console.log(errror.response.data.message);
// 				toast.error(errror.response.data.message);
// 				this.setState({ loading: false });
// 			});
// 	};

// 	handleImage = (event: any) => {
// 		this.setState({
// 			imageUrl: URL.createObjectURL(event.target.files[0]),
// 			imageObject: event.target.files[0],
// 			imageChanged: true,
// 		});
// 	};
// 	validate = () => {
// 		let text = "";

// 		// check image
// 		if (!this.state.imageUrl && !text) {
// 			text = "Banner image";
// 		}

// 		// check title
// 		if (!this.state.title && !text) {
// 			text = "Blog title";
// 		}

// 		// if (this.state.title.length > 150) {
// 		// 	toast.error("Title should be under 150 characters.");
// 		// 	return false;
// 		// }

// 		if (!this.state.animal && !text) {
// 			text = "Animal";
// 		}

// 		// category
// 		if (!this.state.blogCategory && !text) {
// 			text = "Category";
// 		}

// 		// type
// 		if (!this.state.blogType && !text) {
// 			text = "Type";
// 		}

// 		// short description
// 		if (!this.state.shortDescription && !text) {
// 			text = "Short description";
// 		}

// 		// blog content
// 		if (!this.state.content && !text) {
// 			text = "Blog content";
// 		}

// 		if (!!text) {
// 			toast.error(`${text} is Required.`);
// 			return false;
// 		}

// 		return true;
// 	};

// 	removeExtension(filename: string) {
// 		return filename.substring(0, filename.lastIndexOf(".")) || filename;
// 	}

// 	handleSubmit = (event: any) => {
// 		if (event.target.name === "btn2") {
// 			this.props.router.navigate("/blogs");
// 		} else {
// 			if (this.validate()) {
// 				if (this.state.imageChanged) {
// 					this.setState({ loading: true });
// 					this.setState({ loading: true });

// 					let timestamp = new Date();
// 					let filename = `${
// 						// @ts-ignore
// 						this.removeExtension(this.state.imageObject.name)
// 					}_${timestamp.getHours()}${timestamp.getMinutes()}${timestamp.getSeconds()}${timestamp.getMilliseconds()}.${
// 						// @ts-ignore
// 						this.state.imageObject.type.split("/")[1]
// 					}`;
// 					UploadImage({ file: this.state.imageObject, name: filename })
// 						.then(() => {
// 							let image_url = filename;

// 							let data = {
// 								title: this.state.title,
// 								animal: this.state.animal,
// 								description: this.state.content,
// 								shortDescription: this.state.shortDescription,
// 								blogType: this.state.blogType,
// 								blogCategory: this.state.blogCategory,
// 								images: image_url,
// 								banner: "false",
// 							};

// 							callApi({
// 								url: this.props.router.params.id,
// 								data,
// 								method: "patch",
// 							})
// 								.then(() => {
// 									this.setState({ loading: false });
// 									this.props.router.navigate("/blogs");
// 									toast.success("Updated Successfully!");
// 								})
// 								.catch((err) => {
// 									this.setState({ loading: false });
// 									toast.error(err.response.data.message);
// 								});
// 						})
// 						.catch();
// 				} else {
// 					this.setState({ loading: true });
// 					let data = {
// 						title: this.state.title,
// 						animal: this.state.animal,
// 						description: this.state.content,
// 						shortDescription: this.state.shortDescription,
// 						blogType: this.state.blogType,
// 						blogCategory: this.state.blogCategory,
// 						images: this.state.imageUrl,
// 						banner: "false",
// 					};

// 					callApi({ url: this.props.router.params.id, data, method: "patch" })
// 						.then(() => {
// 							this.setState({ loading: false });
// 							this.props.router.navigate("/blogs");
// 							toast.success("Updated Successfully!");
// 						})
// 						.catch(() => {
// 							this.setState({ loading: false });
// 							toast.error("Something went wrong!");
// 						});
// 				}
// 			}
// 		}
// 	};

// 	render() {
// 		return (
// 			<Content>
// 				<ContentHead
// 					title="Edit Blog"
// 					showBtn1={true}
// 					showBtn2={true}
// 					btn1type="filled"
// 					btn2type="outlined"
// 					btn1Name="btn1"
// 					btn2Name="btn2"
// 					btn1Text="Save"
// 					btn2Text="Discard"
// 					onClickHandler={this.handleSubmit}
// 				/>
// 				<ContentBody>
// 					<>
// 						<div
// 							style={{
// 								position: "absolute",
// 								height: "100vh",
// 								width: "100vw",
// 								background: "rgba(0,0,0,.3)",
// 								top: 0,
// 								left: 0,
// 								display: this.state.loading ? "flex" : "none",
// 								alignItems: "center",
// 								justifyContent: "center",
// 								zIndex: 1000,
// 							}}
// 						>
// 							<div className={"spin"}></div>
// 						</div>
// 						<p
// 							style={{
// 								fontSize: "16px",
// 								fontWeight: 500,
// 								transform: "translateX(5px)",
// 								marginBottom: "10px",
// 							}}
// 						>
// 							Banner Image
// 							{this.state.imageUrl && (
// 								<span
// 									style={{
// 										display: "inline-block",
// 										background: "grey",
// 										borderRadius: "10px",
// 										marginLeft: "10px",
// 										padding: "10px",
// 										color: "white",
// 										cursor: "pointer",
// 									}}
// 									onClick={() => {
// 										this.setState({ imageUrl: "", imageObject: "" });
// 									}}
// 								>
// 									Remove
// 								</span>
// 							)}
// 						</p>

// 						{!this.state.imageUrl ? (
// 							<div
// 								style={{
// 									height: "50px",
// 									width: "50px",
// 									textAlign: "center",
// 									background: "transparent",
// 									border: "1px dashed grey",
// 									borderRadius: "10px",
// 									margin: "10px",
// 									cursor: "pointer",
// 									position: "relative",
// 									marginBottom: "20px",
// 									display: "flex",
// 									justifyContent: "center",
// 									alignItems: "center",
// 								}}
// 							>
// 								+
// 								<input
// 									type="file"
// 									style={{
// 										opacity: 0,
// 										position: "absolute",
// 										top: 0,
// 										bottom: 0,
// 										right: 0,
// 										left: 0,
// 									}}
// 									accept="image/*"
// 									onChange={this.handleImage}
// 								></input>
// 							</div>
// 						) : (
// 							<div
// 								style={{
// 									width: "100%",
// 									aspectRatio: "16/9",
// 									backgroundImage: `url('${
// 										this.state.imageChanged
// 											? this.state.imageUrl
// 											: process.env.REACT_APP_IMAGE_BASE_FAST_LINK +
// 											  this.state.imageUrl
// 									}')`,
// 									backgroundSize: "cover",
// 									backgroundPosition: "center",
// 									marginBottom: "40px",
// 									marginTop: "10px",
// 									borderRadius: "15px",
// 									position: "relative",
// 								}}
// 							></div>
// 						)}

// 						<InputField
// 							isLabel={true}
// 							value={this.state.title}
// 							lable="Blog Title"
// 							type="text"
// 							isRequired={false}
// 							placeholder="Blog Heading"
// 							onChange={(event: any) => {
// 								this.setState({ title: event.target.value });
// 							}}
// 						/>

// 						<div
// 							style={{
// 								display: "grid",
// 								gridTemplateColumns: "1fr 1fr",
// 								gap: "20px",
// 								marginTop: "20px",
// 							}}
// 						>
// 							<InputField
// 								type="select"
// 								isLabel={true}
// 								value={this.state.animal}
// 								lable="Animal"
// 								isRequired={false}
// 								placeholder="Blog Heading"
// 								options={animal_dropdown}
// 								accessText={"name"}
// 								accessValue={"value"}
// 								onChange={(event: any) => {
// 									this.setState({
// 										animal: event.target.value,
// 									});
// 								}}
// 							/>
// 						</div>
// 						<div
// 							style={{
// 								display: "grid",
// 								gridTemplateColumns: "1fr 1fr",
// 								gap: "20px",
// 								marginTop: "20px",
// 							}}
// 						>
// 							<InputField
// 								type="select"
// 								isLabel={true}
// 								value={this.state.blogCategory}
// 								lable="Category"
// 								isRequired={false}
// 								placeholder="Blog Heading"
// 								options={[
// 									{ name: "Hero", value: "hero" },
// 									{ name: "Discover 1", value: "discover-1" },
// 									{ name: "Discover 2", value: "discover-2" },
// 									{ name: "Normal", value: "normal" },
// 								]}
// 								accessText={"name"}
// 								accessValue={"value"}
// 								onChange={(event: any) => {
// 									this.setState({
// 										blogCategory: event.target.value,
// 									});
// 								}}
// 							/>
// 							<InputField
// 								type="select"
// 								isLabel={true}
// 								value={this.state.blogType}
// 								lable="Type"
// 								isRequired={false}
// 								placeholder="Blog Heading"
// 								options={[
// 									{ name: "Featured", value: "featured" },
// 									{ name: "Trending", value: "trending" },
// 									{ name: "Regular", value: "regular" },
// 								]}
// 								accessText="name"
// 								accessValue="value"
// 								onChange={(event: any) => {
// 									this.setState({
// 										blogType: event.target.value,
// 									});
// 								}}
// 							/>
// 						</div>
// 						<p
// 							style={{
// 								fontSize: "16px",
// 								fontWeight: 500,
// 								transform: "translateX(5px)",
// 								marginTop: "20px",
// 							}}
// 						>
// 							Short Description
// 						</p>
// 						<textarea
// 							style={{
// 								width: "100%",
// 								marginTop: "10px",
// 								marginBottom: "20px",
// 								borderRadius: "15px",
// 								border: "1px solid black",
// 								padding: "20px",
// 								height: "100px",
// 							}}
// 							value={this.state.shortDescription}
// 							onChange={(event: any) => {
// 								this.setState({ shortDescription: event.target.value });
// 							}}
// 						/>
// 						<p
// 							style={{
// 								fontSize: "16px",
// 								fontWeight: 500,
// 								transform: "translateX(5px)",
// 								marginBottom: "10px",
// 							}}
// 						>
// 							Blog Content
// 						</p>
// 						<TextEditor
// 							content={this.state.content}
// 							handleChange={(value: any) => {
// 								console.log(value);
// 								this.setState({ content: value });
// 							}}
// 						/>
// 					</>
// 				</ContentBody>
// 			</Content>
// 		);
// 	}
// }

class BlogDetails extends Component<any, any> {
  render(): React.ReactNode {
    return <CreateNewBlog />;
  }
}

export default withRouter(BlogDetails);
